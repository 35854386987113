html, body {
  background-color: #0d1525;
  font-family: monospace;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.content {
  flex: 1 0 auto; 
  width: 100%;
}
footer {
  margin-bottom: 1rem;
}
@keyframes screwIt { 
  0%  {transform: scale(0) rotate(360deg);}
  80%  {transform: scale(.8) rotate(0deg);}
  100% {transform: scale(1) rotate(0deg);}
}
@media (orientation: landscape) {
  .graterLogo {
    width: 8rem !important;
    margin-top: 1rem !important;
  }
}
.graterLogo {
  animation: screwIt 3s alternate ease-in-out;
  width: 50%;
  max-width: 380px;
  pointer-events: none;
  margin-top: 4rem;
  border-radius: 50%;
}
p {
  color: white;
  margin-left: auto;
  margin-right: auto;
}
a {
  color:white;
  font-size:x-large;
}
a:hover {
  color:aqua;
}
@media only screen and (max-width: 600px) {
  .teaserTextParagraph {
    width: 94% !important;
  }
}
@media only screen and (min-width: 600px) {
  .teaserTextParagraph {
    min-width: 36rem;
    width: fit-content;
  }
}
.teaserTextParagraph {
  min-height: 0px;
  transition:all 0.5s;
  color: white;
  background-color: black;
  border-left: solid 1px white;
  border-right: solid 1px white;
  line-height: 1.5rem;
  overflow:hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
  .teaserTextParagraph span {
    float: left;
    white-space:pre;
  }
  #teaserTextStart {
    display: none;
    color: aqua;
  }
  #cursor {
    display: none;
    margin: 5px;
    width: 1px;
    height: 1rem;
    background-color: white;
    animation: blink .6s linear infinite alternate;
  }
  @keyframes blink {
    0% {opacity: 1;}
    40% {opacity: 1;}
    60% {opacity: 0;}
    100% {opacity: 0;}
  }

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/